<template>
  <UiVideoShowcaseFull
    v-if="props.mode === 'with_video'"
    v-bind="{
      ...props,
      mappedVideos,
    }"
    @click="passIndex($event)"
  />
  <UiVideoShowcaseCompact
    v-else
    v-bind="{
      header: props.header,
      headerColor: textColor,
      button: props.button,
      mappedVideos,
    }"
    @click="passIndex($event)"
  />
  <UiGallery
    v-if="indexOfVideo !== -1"
    :media="activeVideo"
    @close="passIndex(-1)"
  />
</template>

<script setup lang="ts">
import { getYoutubeVideoId } from '@base/utils/youtube'
import { useYoutubeThumbnail } from '@base/composables/useYoutubeThumbnail'
import UiGallery from '@ui/components/UiGallery/UiGallery.vue'
import UiVideoShowcaseFull from '@ui/components/UiVideoShowcase/UiVideoShowcaseFull/UiVideoShowcaseFull.vue'
import UiVideoShowcaseCompact from '@ui/components/UiVideoShowcase/UiVideoShowcaseCompact/UiVideoShowcaseCompact.vue'
import { computed } from 'vue'
import { getTypo3HeaderColor } from '../../helpers/typo3-colors'
import type { T3CeRawlplugShowcase } from './T3CeRawlplugShowcase.types'

const props = defineProps<T3CeRawlplugShowcase>()

const indexOfVideo = ref(-1)

const textColor = computed(() => getTypo3HeaderColor(props.headerColor))

const mappedVideos = computed(() => {
  return props.tiles?.map((tile) => {
    const videoId = getYoutubeVideoId(tile.video?.properties?.originalUrl) || undefined
    const { thumbnailSrc } = useYoutubeThumbnail(videoId, 'mqdefault')

    return ({
      type: 'video',
      publicUrl: tile.video?.publicUrl || '',
      properties: {
        dimensions: {
          width: tile.video?.properties?.dimensions?.width || 0,
          height: tile.video?.properties?.dimensions?.height || 0,
        },
        title: tile.video.properties?.title || '',
        ytThumbnail: tile.thumbnail?.publicUrl || thumbnailSrc.value,
        type: 'video',
      },
    })
  })
})

const activeVideo = computed(() => {
  return [mappedVideos.value?.[indexOfVideo.value]]
})

function passIndex(index: number) {
  indexOfVideo.value = index
}
</script>
